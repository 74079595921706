import { useEffect, useState } from "react";
import React from 'react';
import { Link } from "react-router-dom";


const Home = () => {

  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch("https://dev.rifhandi.com/api/list")
      .then(response => response.json())
      .then((data) => {
        setData(data??"")
        setLoading(false)
      });
  }, [])

  if (isLoading) return <p className="text-center">Loading...</p>
  if (!data) return <p className="text-center">No profile data</p>

  return (
    <>
      <section className="pt-4">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            {data.map((post, index) => (
              <div className="col-lg-6 col-xxl-4 mb-5">
                <div className="card bg-light border-0 h-100">
                  <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                    <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4"><i className="bi bi-collection"></i></div>
                    <h2 className="fs-4 fw-bold">{post?.name}</h2>
                    <p className="mb-0"><Link to={post?.url} target="_blank">{post?.url}</Link></p>
                    <p className="mb-0"> {post?.type} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
export default Home;
